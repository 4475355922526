// ------------- UI Reducer -------------
import { createSlice } from '@reduxjs/toolkit'

const defaultSort = {
  id: '',
  desc: {},
}

const defaultUsersColumnSelection = {
  acceptsEmailMarketing: false,
  paymentProcessorFees: false,
  capacity: false,
  remaining: false,
  ticketCount: false,
  freeTickets: false,
  paidTickets: false,
  orderCount: false,
  freeOrders: false,
  paidOrders: false,
  preDiscountAmt: false,
  disc: false,
  fee: false,
  grandTotalAmt: false,
  projectedFees: false,
  projectedSubTotal: false,
  projectedGrandTotal: false,
  events: false,
  pastEvents: false,
}

const commonColumnSelection = {
  eventName: false,
  eventDate: false,
  eventImage: false,
  eventRsvp: false,
  fee: false,
  revenue: false,
  ticket: false,
  discount: false,
}

const defaultEventsColumnSelection = {
  ...commonColumnSelection,
  venueName: false,
  venueAddress: false,
  freeOrders: false,
  freeTickets: false,
  totalOrders: false,
  paidOrders: false,
  paidTickets: false,
  freeOrders: false,
  capacity: false,
  hostName: false,
  hostPhone: false,
  hostImage: false,
  hostId: false,
  performance: false,
  paymentProcessorFees: false,
  sessamiFees: false,
}

const defaultOrderColumnSelection = {
  ...commonColumnSelection,
  isCancelled: false,
  isReserved: false,
  isRefunded: false,
  country: false,
  userId: false,
  phone: false,
  hostId: false,
  hostName: false,
  hostEmail: false,
  hostPhone: false,
  isVerified: false,
  paymentProcessorFees: false,
  sessamiFees: false,
}

// #1 Initial state
const initialState = {
  count: {},
  users: [],
  hosts: [],
  events: [],
  orders: [],
  notifications: [],
  mode: 'development',
  table: [
    {
      Users: {
        search: '',
        sort: [{ ...defaultSort }],
        column: {
          ...defaultUsersColumnSelection,
        },
      },
    },
    {
      Hosts: {
        search: '',
        sort: [{ ...defaultSort }],
        column: {
          ...defaultUsersColumnSelection,
        },
      },
    },
    {
      Events: {
        search: '',
        sort: [{ ...defaultSort }],
        column: {
          ...defaultEventsColumnSelection,
        },
      },
    },
    {
      Orders: {
        search: '',
        sort: [{ ...defaultSort }],
        column: {
          ...defaultOrderColumnSelection,
        },
      },
    },
  ],
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setActiveNav: (state, action) => ({ ...state, activeNav: action?.payload || '' }),
    setNotifications: (state, action) => ({ ...state, notifications: action?.payload || [] }),
    setTableFilters: (state, action) => {
      const { tableName, userPreference } = action.payload
      const listIndex = state?.table?.findIndex(item => Object.keys(item)[0] === tableName)

      if (listIndex > 0) {
        state.table[listIndex][tableName] = userPreference
      }
    },
    //
    setCount: (state, action) => ({ ...state, count: action?.payload || {} }),
    setUsers: (state, action) => ({ ...state, users: action?.payload || [] }),
    setHosts: (state, action) => ({ ...state, hosts: action?.payload || [] }),
    setEvents: (state, action) => ({ ...state, events: action?.payload || [] }),
    setOrders: (state, action) => ({ ...state, orders: action?.payload || [] }),
    setMode: (state, action) => ({ ...state, mode: action?.payload || 'development' }),
    //
    resetUI: () => initialState,
  },
})

// Action creators are generated for each case reducer function
export const {
  setNotifications,
  setCount,
  setUsers,
  setHosts,
  setEvents,
  setOrders,
  setMode,
  resetUI,
  setTableFilters,
} = uiSlice.actions

export default uiSlice.reducer
