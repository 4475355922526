import ROUTES from './Routes'

const NavBarData = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Collections',
    subMenu: [
      {
        title: 'Users',
        path: '/users',
      },
      {
        title: 'Orders',
        path: '/orders',
      },
      {
        title: 'Hosts',
        path: '/hosts',
      },
      {
        title: 'Projections',
        path: '/projections',
      },
      {
        title: 'Events',
        path: '/events',
      },
      {
        title: 'Waitlists',
        path: '/waitlists',
      },
    ],
  },

  {
    title: 'Issue Ticket',
    path: '/issueTicket',
  },
  {
    title: 'Gallery',
    path: ROUTES.GALLERY,
  },
  {
    title: 'Alert',
    path: '/alert',
  },
]

export default NavBarData
