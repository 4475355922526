import { combineReducers } from 'redux'

import confirmation from './confirmation'
import eventsReducer from './events'
import loadingReducer from './loading'
import uiReducer from './ui'
import userReducer from './user'

// ------------- Root Reducer -------------
const rootReducer = combineReducers({
  ui: uiReducer,
  user: userReducer,
  events: eventsReducer,
  loading: loadingReducer,
  confirmation: confirmation,
})

// Export Reducers
export * from './events'
export * from './ui'
export * from './user'
export * from './loading'
export * from './confirmation'

// Export RootReducer
export default rootReducer
