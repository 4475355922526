import { collection, getCountFromServer, getDocs, limit, query, where } from 'firebase/firestore'
import { useDispatch } from 'react-redux'
import { db } from 'services/firebaseClient'
import {
  setCount,
  setEvents,
  setNotifications,
  setOrders,
  setUserInfo,
  setUsers,
} from 'store/reducers'

export default function useFetchData() {
  const dispatch = useDispatch()

  const fetchUserData = async () => {
    const usersRef = query(collection(db, 'users'), where('email', '!=', ''), limit(10))
    const queryUsersSnapshot = await getDocs(usersRef)
    const users = queryUsersSnapshot.docs.map(doc => doc.data())
    console.log('fetched user data!', users.length)
    dispatch(setUsers(users))
  }

  const fetchLoggedInUserData = async email => {
    if (!email) return

    const userRef = query(collection(db, 'users'), where('email', '==', email))
    const queryUserSnapshot = await getDocs(userRef)
    const user = queryUserSnapshot.docs.map(doc => doc.data())
    console.log('login user', user)
    dispatch(setUserInfo(user))
  }

  const fetchEventData = async () => {
    const eventsRef = query(collection(db, 'events'), where('eventId', '!=', ''), limit(10))
    const queryEventsSnapshot = await getDocs(eventsRef)
    const events = queryEventsSnapshot.docs.map(doc => doc.data())
    console.log('fetched event data!', events.length)
    dispatch(setEvents(events))
  }

  const fetchOrderData = async () => {
    const orderRef = query(collection(db, 'orders'), where('id', '!=', ''), limit(10))
    const queryOrdersSnapshot = await getDocs(orderRef)
    const orders = queryOrdersSnapshot.docs.map(doc => doc.data())
    console.log('fetched order data!', orders.length)
    dispatch(setOrders(orders))
  }

  const fetchNotificationData = async () => {
    const notificationsRef = query(
      collection(db, 'notifications'),
      where('title', '!=', ''),
      limit(10)
    )
    const queryNotificationsSnapshot = await getDocs(notificationsRef)
    const notifications = queryNotificationsSnapshot.docs.map(doc => doc.data())
    console.log('fetched notification data!', notifications.length)
    dispatch(setNotifications(notifications))
  }

  const fetchProdCount = async () => {
    // const userSS = await getCountFromServer(collection(db, 'users'))

    const buyersQuery = query(collection(db, 'users'), where('userType', '==', 'user'))
    const buyersSS = await getCountFromServer(buyersQuery)
    const totalBuyers = buyersSS.data().count

    const sellersQuery = query(collection(db, 'users'), where('userType', '==', 'host'))
    const sellersSS = await getCountFromServer(sellersQuery)
    const totalSellers = sellersSS.data().count

    const eventsSS = await getCountFromServer(collection(db, 'events'))
    const ordersSS = await getCountFromServer(collection(db, 'orders'))

    const count = {
      users: {
        total: totalBuyers + totalSellers,
        buyers: totalBuyers,
        sellers: totalSellers,
      },
      events: {
        total: eventsSS.data().count,
        // published:
        // unPublished:
        // private:
        // expired:
        // happening:
        // upcoming:
      },
      orders: {
        total: ordersSS.data().count,
        // cancelled: 0,
      },
    }

    dispatch(setCount(count))
    console.log('Prod DB Records Count\n', count)
  }

  const fetchProdData = async () => {
    fetchUserData()
    fetchEventData()
    fetchOrderData()
    fetchProdCount()
    fetchLoggedInUserData()
    // fetchNotificationData()
  }

  return {
    fetchProdData,
    fetchEventData,
    fetchOrderData,
    fetchNotificationData,
    fetchUserData,
    fetchLoggedInUserData,
  }
}
