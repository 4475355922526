import { BiExit } from '@react-icons/all-files/bi/BiExit'
import { BiMenu } from '@react-icons/all-files/bi/BiMenu'
import { IoMdArrowDropdown } from '@react-icons/all-files/io/IoMdArrowDropdown'
import { IoMdArrowDropright } from '@react-icons/all-files/io/IoMdArrowDropright'
import { TbLogout } from '@react-icons/all-files/tb/TbLogout'
import logo from 'assets/logo.png'
import cn from 'clsx'
import NavBarData from 'consts/NavBarData'
import ROUTES from 'consts/Routes'
import useFetchData from 'hooks/useFetchData'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { auth } from 'services/firebaseClient'
import { resetUI, setMode, setUserAuth } from 'store/reducers'
import deleteCookie from 'utils/deleteCookie'

const MobNav = ({ mobNav, setMobNav, fetchRelevantData, handleSignOut }) => {
  const [openSubMenu, setOpenSubMenu] = useState(false)

  const handleLinkClick = () => {
    setMobNav(false)
    setOpenSubMenu(false)
  }

  return (
    <>
      <div
        className={cn(
          mobNav
            ? 'fixed bg-black/60 opacity-1  top-0 left-0 h-screen w-screen z-40 transition-all duration-300'
            : 'hidden bg-black/60 opacity-0  top-0 left-0 h-screen w-screen z-40 transition-all duration-300'
        )}
        onClick={() => setMobNav(false)}
      />
      <div
        className={cn(
          mobNav
            ? 'w-full md:w-96 bg-base-100 transition-all ease-in-out delay-300 duration-300 fixed top-0 right-0 h-screen z-50 text-center'
            : 'w-0 opacity-0 bg-base-100 transition-all ease-in-out delay-300 duration-300 fixed top-0 right-0 h-screen z-50 text-center'
        )}>
        <div className='p-16 flex flex-col justify-center items-center h-full'>
          <div className='flex justify-end w-full'>
            <BiExit className='text-secondary' size={35} onClick={() => setMobNav(false)} />
          </div>

          <div className='shadow-lg  rounded-lg py-10 px-12 '>
            {NavBarData.map(nav => (
              <div className='py-4 border-b border-base-300' key={nav.title}>
                {nav.subMenu ? (
                  <div>
                    <div className='flex justify-between items-center '>
                      <p
                        className='fontBold cursor-pointer'
                        onClick={() => setOpenSubMenu(!openSubMenu)}>
                        {nav.title}
                      </p>
                      {openSubMenu ? (
                        <IoMdArrowDropdown
                          IoMdArrowDropup
                          size={30}
                          onClick={() => setOpenSubMenu(!openSubMenu)}
                        />
                      ) : (
                        <IoMdArrowDropright
                          size={30}
                          onClick={() => setOpenSubMenu(!openSubMenu)}
                        />
                      )}
                    </div>
                    {openSubMenu &&
                      nav.subMenu.map(subNav => (
                        <div className='text-left  py-3  rounded-lg' key={subNav.title}>
                          <Link href={subNav.path || '#'} onClick={handleLinkClick}>
                            <p className='fontBold hover:scale-110 duration-300'>{subNav.title}</p>
                          </Link>
                        </div>
                      ))}
                  </div>
                ) : (
                  <Link href={nav.path || '#'}>
                    <p className='fontBold hover:scale-110 duration-300'>{nav.title}</p>
                  </Link>
                )}
              </div>
            ))}
            <div className='py-2'>
              <p
                className='fontBold hover:scale-110 duration-300'
                onClick={() => fetchRelevantData()}>
                Refresh Data
              </p>
            </div>

            <div className='py-2'>
              <p className='fontPrimary hover:scale-110 duration-300' onClick={handleSignOut}>
                Sign out
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Header = () => {
  const dispatch = useDispatch()
  const { fetchProdData } = useFetchData()

  const router = useRouter()

  const loggedInUser = useSelector(state => state?.pr?.user?.auth)

  const [mobNav, setMobNav] = useState(false)

  const handleSignOut = () => {
    auth.signOut()
    dispatch(setUserAuth(false))
    dispatch(resetUI())

    // Also delete cookie from the browser
    deleteCookie('adminUser')

    router.push(ROUTES.SIGN_IN)
    dispatch(setMode('development'))
    setMobNav(false)
  }

  const fetchRelevantData = () => {
    fetchProdData()
    setMobNav(false)
  }

  if (router.pathname === ROUTES.SIGN_IN) return null

  return (
    <>
      <div className='bg-black text-white p-2 flex justify-between fixed top-0 w-screen z-50'>
        <div className='flex items-center'>
          <Image src={logo} className='w-4   mx-2' alt='logo-image' height={300} width={300} />
          <h2 className='md:text-xl uppercase font-bold tracking-[5px] fontSecondary'>Admin</h2>
        </div>

        <div className='flex md:hidden items-center'>
          <BiMenu size={25} onClick={() => setMobNav(true)} />
        </div>

        <div className='hidden md:flex items-center space-x-4'>
          {NavBarData.map(nav => (
            <React.Fragment key={nav.title}>
              {nav.subMenu ? (
                nav.subMenu.map(subNav => (
                  <div key={subNav.title}>
                    <Link href={subNav.path || '#'}>
                      <p
                        className={cn(
                          'text-sm',
                          router.pathname === subNav.path && 'badge badge-secondary'
                        )}>
                        {subNav.title}
                      </p>
                    </Link>
                  </div>
                ))
              ) : (
                <div className='' key={nav.title}>
                  <Link href={nav.path || '#'}>
                    <p
                      className={cn(
                        'text-sm',
                        router.pathname === nav.path && 'badge badge-secondary'
                      )}>
                      {nav.title}
                    </p>
                  </Link>
                </div>
              )}
            </React.Fragment>
          ))}

          {loggedInUser && (
            <>
              {/* <FiRefreshCw
                onClick={() => fetchRelevantData()}
                size={25}
                className='mx-2 hover:animate-spin duration-700'
              /> */}

              <button
                className='btn btn-sm hover:scale-110 duration-300 btn-secondary'
                onClick={handleSignOut}>
                <TbLogout />
              </button>
            </>
          )}
        </div>
      </div>

      <MobNav
        mobNav={mobNav}
        setMobNav={setMobNav}
        fetchRelevantData={fetchRelevantData}
        handleSignOut={handleSignOut}
      />
    </>
  )
}

export default Header
