import Container from 'components/containers/Container'
import GridContainer from 'components/containers/GridContainer'
import useKeyPress from 'hooks/useKeyPress'
import { useEffect, useState } from 'react'

// Grid Overlay for toggling grid system
const GridOverlay = () => {
  const [isGridActive, setGridStatus] = useState(false)
  // const [isGridActive, setGridStatus] = useState((process.env.APP_ENV = 'development'))

  // Track multi-key press
  const ctrlPress = useKeyPress('control') // key - ctrl
  const gPress = useKeyPress('g') // key - g

  useEffect(() => {
    if (ctrlPress && gPress) setGridStatus(!isGridActive)
  }, [ctrlPress, gPress])

  return (
    <div id='grid-overlay' className='relative flex justify-center z-50'>
      {isGridActive && (
        <Container className='fixed z-50 h-screen text-center text-white pointer-events-none'>
          <GridContainer className='h-screen'>
            <div className='text-white col-span-1 bg-gray-900/20'>1</div>
            <div className='text-white col-span-1 bg-gray-900/20'>2</div>
            <div className='text-white col-span-1 bg-gray-900/20'>3</div>
            <div className='text-white col-span-1 bg-gray-900/20'>4</div>
            <div className='text-white col-span-1 bg-gray-900/20 hidden md:block'>5</div>
            <div className='text-white col-span-1 bg-gray-900/20 hidden md:block'>6</div>
            <div className='text-white col-span-1 bg-gray-900/20 hidden md:block'>7</div>
            <div className='text-white col-span-1 bg-gray-900/20 hidden md:block'>8</div>
            <div className='text-white col-span-1 bg-gray-900/20 hidden md:block'>9</div>
            <div className='text-white col-span-1 bg-gray-900/20 hidden md:block'>10</div>
            <div className='text-white col-span-1 bg-gray-900/20 hidden md:block'>11</div>
            <div className='text-white col-span-1 bg-gray-900/20 hidden md:block'>12</div>
          </GridContainer>
        </Container>
      )}
    </div>
  )
}

export default GridOverlay
