import { initializeApp } from 'firebase/app'
import { GoogleAuthProvider, getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FB_APIKEY || 'mock_key',
  authDomain: process.env.NEXT_PUBLIC_FB_AUTHDOMAIN,
  projectId: process.env.NEXT_PUBLIC_FB_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FB_STORAGEBUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FB_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FB_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FB_MEASUREMENT_ID,
}

const firebaseApp = initializeApp(firebaseConfig)

const auth = getAuth(firebaseApp) // share single auth across other services - storage, firestore etc
const storage = getStorage(firebaseApp) // storage is for media - images, files etc
const db = getFirestore(firebaseApp) // collections - events, users, orders
const provider = new GoogleAuthProvider()

export { db, storage, auth, provider, firebaseApp as admin }
