// ------------- UI Reducer -------------
import { createSlice } from '@reduxjs/toolkit'

// #1 Initial state
const initialState = {
  info: {},
  auth: false,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo: (state, action) => ({ ...state, info: action?.payload }),
    setUserAuth: (state, action) => ({ ...state, auth: action?.payload }),
    //
    resetUser: () => ({ ...initialState }),
  },
})

// Action creators are generated for each case reducer function
export const {
  setUserInfo,
  resetUser,
  setUserAuth,
  //
  setUserLocation,
  setUserCheckIns,
  setUserPurchasedTickets,
} = userSlice.actions

export default userSlice.reducer
