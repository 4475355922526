import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync'
import rootReducer from './reducers'

const persistConfig = {
  key: 'root',
  version: 1,
  storage, // defaults to localStorage for web
  blacklist: ['persist/PERSIST', 'persist/REHYDRATE'],
}

// https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
const pr = persistReducer(persistConfig, rootReducer) // pr - persistedReducer

export const store = configureStore({
  reducer: { pr },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
  //   .concat([
  //   createStateSyncMiddleware({
  //     ...pr,
  //     blacklist: ['persist/PERSIST', 'persist/PURGE', 'persist/REHYDRATE'],
  //   }),
  // ]),
})

// initStateWithPrevTab(store)
export const persistor = persistStore(store)
