import ProgressBar from '@badrap/bar-of-progress'

/* eslint-disable-next-line */
import { Figtree, Plus_Jakarta_Sans } from 'next/font/google'
import { Router } from 'next/router'
import { ToastContainer } from 'react-toastify'

const figtree = Figtree({
  subsets: ['latin'],
  variable: '--font-figtree',
  display: 'swap',
})

const plusJakartaSans = Plus_Jakarta_Sans({
  subsets: ['latin'],
  variable: '--font-plusJakartaSans',
  display: 'swap',
})

const progress = new ProgressBar({
  size: 3,
  color: '#E8336A',
  className: 'z-50',
  delay: 0,
})

Router.events.on('routeChangeStart', progress.start)
Router.events.on('routeChangeComplete', progress.finish)
Router.events.on('routeChangeError', progress.finish)

const GlobalStyles = () => {
  return (
    <>
      {/* Using next/font */}
      {/* Note: ClassName Approach is not working */}
      {/* https://nextjs.org/docs/pages/building-your-application/optimizing/fonts */}
      <style jsx global>{`
        html {
          font-family: ${figtree.style.fontFamily};
        }
        h1,
        h1 *,
        h2,
        h2 *,
        h3,
        h3 *,
        h4,
        h4 *,
        h5,
        h5 *,
        h6,
        h6 * {
          font-family: ${plusJakartaSans.style.fontFamily};
        }
      `}</style>

      <ToastContainer
        position='bottom-right'
        autoClose={5000}
        // hideProgressBar={true}
        newestOnTop={true}
        theme='colored'
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        bodyClassName={'font-[Figtree]'}
      />
    </>
  )
}

export default GlobalStyles
