import cn from 'clsx'

const MainContainer = ({ children, className }) => {
  return (
    <main className={cn('h-screen', className)} id='AppMain'>
      {children}
    </main>
  )
}

export default MainContainer
