import GlobalStyles from 'components/GlobalStyles'
import Header from 'components/Header'
import MainContainer from 'components/containers/MainContainer'
import ConfirmationDialog from 'components/molecules/ConfirmationDialog'
import GridOverlay from 'components/molecules/GridOverlay'
import { Provider } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from 'store'
import 'styles/font.scss'
import 'styles/global.scss'
import 'styles/tailwind.scss'

const App = ({ Component, pageProps }) => {
  const getLayout = Component.getLayout || (page => page)
  console.log('Hello From Beta branch!')
  console.log('process.env', process.env)
  console.log('process.env.NODE_ENV', process.env.NODE_ENV)
  console.log('process.env.ANALYZE_BUNDLE', process.env.ANALYZE_BUNDLE)
  console.log('process.env.NEXT_PUBLIC_APP_ENV', process.env.NEXT_PUBLIC_APP_ENV)
  console.log('process.env.NEXT_PUBLIC_PRODUCTION_URL', process.env.NEXT_PUBLIC_PRODUCTION_URL)
  console.log('process.env.NEXT_PUBLIC_FB_APIKEY', process.env.NEXT_PUBLIC_FB_APIKEY)

  return (
    <>
      <GlobalStyles />

      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConfirmationDialog />
          <Header />
          <MainContainer className='pt-20'>
            <GridOverlay />
            <>{getLayout(<Component {...pageProps} />)}</>
          </MainContainer>
        </PersistGate>
      </Provider>
    </>
  )
}
export default App
